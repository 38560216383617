// import { createRouter, createWebHashHistory } from 'vue-router'
import { createRouter, createWebHistory } from 'vue-router'

// Import your route components
import Home from '../views/Home.vue'
import About from '../views/About.vue'
import CodeSummit from '../views/CodeSummit.vue'
// import Rwanda912 from '../views/912Rwanda.vue'
import ContactUs from '../views/ContactUs.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/about-us',
    name: 'About',
    component: About,
  },
  {
    path: '/contact-us',
    name: 'ContactUs',
    component: ContactUs,
  },
  {
    path: '/codesummit',
    name: 'CodeSummit',
    component: CodeSummit
  },
  // {
  //   path: '/912Rwanda',
  //   name: 'Rwanda912',
  //   component: Rwanda912,
 
  // }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

router.beforeEach((to, from, next) => {
  // Your meta tags handling logic goes here
  next()
})

export default router
