<template>
  <div>
    <div id="app" class="row" v-if="isVisible">
      <div
        class="image-frame col-lg-3 col-md-6 col-sm-10 d-flex flex-wrap p-2"
        v-for="i in 12"
        :key="i"
        :data-image="i"
        :data-flipped="false"
        style="height: auto"
      >
        <img :src="require(`@/assets/images/gallery/gal_${i}.png`)" alt="" />
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name:"galleryFlip",
  data() {
    return {
      shownImages: new Set(), // Keep track of the images already shown
      isVisible:false,
    };
  },
  mounted() {
    setInterval(this.autoFlipImage, 1000);
    // apply lazy loading 
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          this.isVisible = true;
          observer.disconnect(); 
        }
      },
      {
        root: null,
        threshold: 0.1, 
      }
    );
    observer.observe(this.$el);
  },
  methods: {
    autoFlipImage() {
      const imageFrames = document.querySelectorAll(".image-frame");
      let allFlipped = true;
      imageFrames.forEach((frame) => {
        if (frame.dataset.flipped === "false") {
          allFlipped = false;
        }
      });
      if (allFlipped) {
        // Reset the individual flipped status of each image frame
        imageFrames.forEach((frame) => {
          frame.dataset.flipped = "false";
          frame.classList.remove("flipped");
        });
        this.shownImages.clear();
      }
      // Select a random image frame to flip
      const unflippedFrames = Array.from(imageFrames).filter(
        (frame) => frame.dataset.flipped === "false"
      );
      // after having unflippedFrames  array let select random mimage to flip
      if (unflippedFrames.length > 0) {
        const randomIndex = Math.floor(Math.random() * unflippedFrames.length);
        const imageFrame = unflippedFrames[randomIndex];
        imageFrame.dataset.flipped = "true";
        imageFrame.classList.add("flipped");
        setTimeout(() => {
          this.showRandomImage(imageFrame);
        }, 500);
      }
    },

    showRandomImage(imageFrame) {
      const imagesCount = 37;
      const currentImage = parseInt(imageFrame.dataset.image);
      const uniqueImagesCount = imagesCount - this.shownImages.size;

      if (uniqueImagesCount <= 1) {
        // All images have been shown, reset the shownImages Set
        this.shownImages.clear();
      }
      /* A loop generates a random image index (randomImage) until it finds one
      that is not the same as the current image and has not been shown before(not present in shownImages).
      */
      let randomImage;
      do {
        randomImage = Math.floor(Math.random() * imagesCount) + 1;
      } while (
        randomImage === currentImage ||
        this.shownImages.has(randomImage)
      );
      // The selected random image is added to the shownImages set to track its display.
      this.shownImages.add(randomImage);
      const img = imageFrame.querySelector("img");
      img.src = require(`@/assets/images/gallery/gal_${randomImage}.png`);
      imageFrame.dataset.image = randomImage;
      imageFrame.dataset.flipped = "false";
      imageFrame.classList.remove("flipped");
    },
  },
};
</script>
<style>
.image-frame {
  perspective: 1000px;
  transition: transform 1.6s cubic-bezier(0.4, 0, 0.2, 1);
}

.flipped {
  transform: rotateY(180deg);
}
</style>


