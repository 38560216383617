<template>
  <!-- Start of Footer section -->
  <section class="dark">
    <footer class="mx-5" id="footer">
      <div class="container-fluid">
        <div class="row text-light py-4">
          <div class="col-lg-6 col-md-6 col-sm-6">
            <p class="footer">
              <span class=" mt-2"> > </span>The Rwanda Build
              Program (RWBuild) is a tech hub in Kigali, Rwanda.
            </p>
            <p class="footer">
              <span class="  mt-2 mb-2"> > </span> 
              Rooftop Ikawa Cafe is our Tech Lab
              <a href="https://ikawa.rw/" target="blank" style="color:rgba(245, 245, 245, 0.872) !important">ikawa.rw</a>
            </p>
            <p class="footer">
              <span class="  mt-2 mb-2"> > </span>
             All RWBuild Teams & Projects are 100% African built in Kigali, Rwanda
            </p>
            <!-- <p class="footer">
              <span class="  mt-2 mb-2"> > </span>
              Location/Phone: 34 KN 41 Street, Kiyovu Kigali, Rwanda
              Phone: +250 788930101
            </p> -->
            <img src="@/assets/images/logo.png" class="img-fluid" width="100" alt="RWBuild logo" />
          </div>
          <div class="col-lg-6 col-md-6 col-sm-6">
            <p class="footer ml-md-4 ml-0 mt-1">
              <span class="  mt-1"> > </span>RWBuild is a
              social enterprise supported and funded in part by Common World
              Inc
            </p>
            <p class="footer ml-md-4 ml-0 " style="cursor:pointer">
              <span class="  mt-2"> > </span>
              <a href="/contact-us" style="color:rgba(245, 245, 245, 0.872) !important">Contact Us</a>
            </p>
            <ul class="list-inline mx-md-3 mx-0 list" id="lists">
              <span class="ml-2"
                ><a href="http://www.facebook.com/RWBuild" target="blank"
                  ><p class="list-inline-item" id="facebook">
                    <i class="fab fa-facebook text-white"></i></p></a
              ></span>
              <span class="ml-2"
                ><a href="https://twitter.com/RwandaBuild" target="blank"
                  ><p class="list-inline-item">
                    <i class="fab fa-twitter text-white"></i></p></a
              ></span>
              <span class="ml-2"
                ><a href="http://www.linkedin.com/company/rwanda-build-program/" target="blank"
                  ><p class="list-inline-item">
                    <i class="fab fa-linkedin fa-1x text-white"></i></p></a
              ></span>
              <span class="footer ml-md-2 ml-0 text-light ml-2" style="color:rgba(245, 245, 245, 0.872) !important">Email :<a href="mailto:RWBuild@commonworldinc.com" target="blank">
                  RWBuild@RwandaBuildProgram.com</a></span>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  </section>
</template>

<script>
export default {
  name: "footer_comp",
  props: {},
  data() {
    return {};
  },
   
  mounted() {},
  methods: {
    goToContactUs() {
      this.$router.push("/contact-us");
    },
  },
  computed: {},
  watch: {},
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.footer {
  font-size: 13px !important;
  line-height:1.55rem;
  word-spacing:0.024rem;
  color:rgba(245, 245, 245, 0.872) !important
}
.list {
  font-size: 12px;
}


@media only screen and (max-width: 375px) {
  body #footer,
  .ml-5,
  .mx-5 {
    margin-left: 0.5rem !important;
  }
  #footer,
  .mr-5,
  .mx-5 {
    margin-right: 0.5rem !important;
  }
}

@media only screen and (max-width: 414px) {
  body #footer,
  .ml-5,
  .mx-5 {
    margin-left: 0.5rem !important;
  }
  #footer,
  .mr-5,
  .mx-5 {
    margin-right: 0.5rem !important;
  }
}

@media only screen and (max-width: 375px) {
  body #lists,
  .ml-4,
  .mx-4 {
    margin-left: 2.5rem !important;
  }
  #lists,
  .mr-4,
  .mx-4 {
    margin-right: 2.5rem !important;
  }
}

@media only screen and (max-width: 414px) {
  body #lists,
  .ml-4,
  .mx-4 {
    margin-left: 0rem !important;
  }
  #lists,
  .mr-4,
  .mx-4 {
    margin-right: 0rem !important;
  }
 
}

.align {
  vertical-align: middle;
}

.dark {
  background-color: black;
}
p {
    margin-top: 0;
    margin-bottom: 0rem!important;
}
</style>
