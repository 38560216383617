<template>
    <!-- Our project section -->
    <section class="fadeInUp recently-project mx-md-5 mx-sm-4 px-3 pt-3 mb-lg-0 mb-md-5 mb-sm-5" id="current" ref="projct_ref">
        <div class="widget" data-aos="fade-up" data-aos-delay="150" data-aos-duration="1000" data-aos-easing="ease-in-out"
            data-aos-mirror="false" data-aos-once="false">
            <div class="content">
                <h4 class="heading font-bold current-project">
                    Our Current Projects
                </h4>
                <div class="d-flex row" v-if="isVisibleProjects">
                    <div   class="d-flex col-lg-4 col-md-6 col-sm-10" v-for="(project, index) in projects"
                        :key="index + 'djdjs'" style="width: 100% !important"  >
                        <!-- <div class="d-flex project-widget"> -->
                        <div class="card" style="width: 100% !important">
                            <div class="project-img">
                                <img class="img-responsive animated-right" :src="require(`@/assets/images/project/${project.img_name}`)
                                    " alt="project" />
                            </div>

                            <div>
                                <h5 v-if="project.link" class="summit-link">
                                    <a :href="project.link" v-html="project.title" target="_blank"></a>
                                </h5>
                                <h5 v-else>
                                    <a :href="project.link" v-html="project.title" target="_blank"
                                        class="summit-no-link"></a>
                                </h5>
                                <p class="projects" v-html="project.content"></p>
                                <span class="projects" v-if="project.fblink || project.tweeterlink">
                                    Follow us:
                                    <a v-if="project.fblink" :href="project.fblink" target="_blank"
                                        style="text-decoration: none; color: black">Facebook ,</a>
                                    <a v-if="project.tweeterlink" :href="project.tweeterlink" target="_blank"
                                        style="text-decoration: none; color: black">
                                        Tweeter</a>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- </div> -->
    </section>
</template>

<script>
export default {
    name: "currentProjects",
    data() {
        return {
            isVisibleProjects:false,
            projects: [
                {
                    img_name: "Murugo.png",
                    title: "Murugo",
                    content:
                        " The MurugoCloud is set to become the primary digital ecosystem  for Africa. It will serve as your digital home, starting in Kigali, Rwanda, with single  sign-on access, extensive location services, notifications, user and contact  management, user verification, syncing, software development tools, and many  other features.",
                    // "The Murugo Cloud will be the essential digital ecosystem for Africa. It will be your digital home. Designed and developed in Kigali. #RwandaCode100%",
                    link: "https://murugo.cloud/",
                },
                {
                    img_name: "Guhemba.jpg",
                    title: "Guhemba",
                    content:
                        "Guhemba is a platform & super-app supporting mobile and e commerce payments, person-to-person transfers, and a suite of other powerful  services, all through unified digital Wallets (personal & merchant). Guhemba is  designed to fit the future culture of transferring funds and making payments across  Africa. A Rwandan company proudly develops the product, which is being tested in  Kigali with outstanding results",
                    link: "https://guhemba.rw/",
                    tweeterlink: "https://twitter.com/guhemba?lang=en",
                },
                {
                    img_name: "912RW.png",
                    title: "912 Rwanda",
                    content:
                        "912Rwanda, an innovative online dispatch and ambulance  solution that is revolutionizing emergency medical services in Kigali, Rwanda. The  primary aim of 912Rwanda Phase 1 was to reduce the overall travel time of  emergency ambulances in Kigali, and to launch the foundational dispatch and  ambulance software platform. The platform provides a unified control system for  pre-hospital operations, making it a highly efficient solution. The project was  planned and designed by RWBuild in collaboration with the Ministry of Health and  international global health partners. Phase 2 will introduce facility decision logic and  enhanced patient data collection.",
                    link: "https://912rwanda.com/",
                },
                {
                    img_name: "Bash.jpg",
                    title: "Events-Bash",
                    content:
                        'The popular website Events Bash is the number one online  platform in Kigali for finding all the latest happenings and events in Kigali. "Events  Bash" is a well-known site where event planners can list their events and even sell  tickets. It is an excellent service for hosts who need to keep track of their ticket  sales and manage the scanning of tickets at the door.',
                    link: "https://eventsbash.rw/",
                },
                {
                    img_name: "helloKigali.jpg",
                    title: "Hello Kigali",
                    content:
                        "Hello Kigali is a city discovery app (and so much more). This  app was built by local street teams venturing out to find the best spots and their  exact locations. It could be the most trusted app for where places in Rwanda are  exactly located. Currently, Hello Kigali has over 3,000 sites like restaurants, cafes,  nightclubs, hotels, embassy, shopping, medical, tourism, wifi locations, and other  desired locations. There is also a Taxi/Moto cost estimator and built-in directions.",
                    link: "https://hellokigali.com",
                    fblink: "https://www.facebook.com/HelloKigali/",
                    tweeterlink: "https://twitter.com/hellokigali?lang=en",
                },
                {
                    img_name: "candidate.png",
                    title: "Candidate",
                    content:
                        "The Candidate platform enables anyone to find open positions  and job opportunities with startups, businesses, and other organizations. The  platform uses advanced matching technologies to help candidates find the right  jobs, opportunities, and programs that suit their skills and experience. Notably, this  platform has a unique feature that helps startup founders connect with investors  and stakeholders.",
                    link: "https://candidate.rw/",
                },
                // {
                //   img_name: "",
                //   title: "",
                //   content:"",
                //   // link: "#",
                // },
                {
                    img_name: "chat_boot.gif",
                    title: "Murugo Chat",
                    content:
                        "An exciting new Murugo Service to be launched in the future.",
                    // link: "#",
                },
                {
                    img_name: "KQ.png",
                    title: "Kigali Quest & Domination",
                    content: "Social location-based Games to be launched in the future.",
                    // link: "#",
                },
            ],
        }
    },
     watch: {
        isVisibleProjects(newValue) {
            if (newValue) {
                // Initialize Swiper when content is visible
                this.$nextTick(() => {
                    // this.setThumbsSwiper();
                });
            }
        },
    },
    mounted() {
        const sectionEl = this.$refs.projct_ref;
        if (sectionEl) {
            const observer = new IntersectionObserver(
                (entries) => {
                    if (entries[0].isIntersecting) {
                        this.isVisibleProjects = true;
                        observer.disconnect(); // Stop observing once it's loaded
                    }
                },
                {
                    root: null, // Use the viewport
                    threshold: 0.1, // Trigger when 10% of the section is in view
                }
            );
            observer.observe(sectionEl);
        } else {
            console.error('Failed to find section element for observation.');
        }
    },

}
</script>

<style scoped lang="scss">
$assets: "~@/assets/";

.event-link {
    color: rgba(245, 245, 245, 0.872);
    cursor: pointer;
}

.event-link:hover {
    color: rgba(245, 245, 245, 0.872) !important;
    cursor: pointer;
}

.str1 {
    stroke: #bdbfc1;
    stroke-width: 6.94488;
}

.str0 {
    stroke: #96938e;
    stroke-width: 6.94488;
    stroke-linecap: round;
    stroke-linejoin: round;
}

.fil1 {
    fill: none;
}

.fil0 {
    fill: none;
    fill-rule: nonzero;
}

.banner_image {
    padding-top: 20px;

    img {
        width: 100%;
    }

    .play_btn {
        height: 60px;
        width: 60px;
        border-radius: 50%;
        background: #0000008a;
        font-size: 30px;
        position: absolute;
        left: 38px;
        top: 44px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 3px solid #3c3c3c;

        span {
            color: #fff;
            margin-left: 9px;
        }
    }

    .play_btn::before,
    .play_btn::after {
        content: "";
        display: block;
        position: absolute;
        border: 50%;
        border: 3px solid #797979;
        left: -20px;
        right: -20px;
        top: -20px;
        bottom: -20px;
        border-radius: 50%;
        animation: animate 1s linear infinite;
        opacity: 0;
    }

    .play_btn:after {
        animation-delay: 0.5s;
    }

    @keyframes animate {
        0% {
            transform: scale(0.5);
            opacity: 0;
            border: 3px solid #797979;
        }

        50% {
            opacity: 1;
            border: 2px solid #b7b4b4;
        }

        100% {
            transform: scale(1.2);
            opacity: 0;
            border: 1px solid #ccc;
        }
    }
}

.more-info {
    text-transform: capitalize;
    font-size: 15px;
}

.event-image-widget {
    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        -o-object-fit: cover;
        border-radius: 8px;
    }
}

.swiper-container {
    padding-top: 15px;
}

.swiper-slide.swiper-slide-active {
    img {
        -webkit-transform: scale(1.2);
        transform: scale(1.2);
        -webkit-transition: 1s ease-in-out;
        transition: 1s ease-in-out;
    }
}

.event-carousel {
    .headline {
        margin-bottom: 8px;
    }
}

.event-title {
    line-height: 14px;
    margin-top: 4px;
    color: #696969;
    font-size: 22px;
    font-weight: 900;
    -webkit-font-smoothing: antialiased;

    a {
        text-decoration: none;
        color: black;

        &:hover {
            text-decoration: underline;
        }
    }
}

.event-description,
.event-date {
    color: gray;
    font-size: 16px;
    font-weight: lighter;
    -webkit-font-smoothing: antialiased;
}

.event-date {
    margin-top: -4px;
}

.event {
    width: 175px;
    /*Adding margins on left and right as with value auto allows the box to center itself in
        the parent(swiper-slide) div*/
    margin-right: auto;
    margin-left: auto;

    @media (max-width: 600px) {
        width: 100%;
    }

    .event-image {
        height: 75px;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 8px;
        }
    }

    .event-footer {
        padding-bottom: 8px;
    }
}

.summit-no-link {
    text-decoration: none;
    color: black;
    font-weight: 900;
    font-family: "Proxima Nova";
}

.summit-no-link:hover {
    text-decoration: none;
    color: black !important;
    font-weight: bold;
}

.summit-link {
    text-decoration: underline;
    color: #007bff !important;
}

.summit-link,
a:hover {
    text-decoration: underline;
    color: #007bff !important;
}

.image-card {
    margin: auto;
    vertical-align: middle;
}

.image {
    max-height: 140px;
}

.paragraph {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    /* number of lines to show */
    -webkit-box-orient: vertical;
    word-wrap: break-word;
    font-size: 13px;
}

.upcoming {
    font-size: 16px;
}

.current {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 6;
    /* number of lines to show */
    -webkit-box-orient: vertical;
    word-wrap: break-word;
}

.trainings {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 6;
    /* number of lines to show post*/
    -webkit-box-orient: vertical;
    word-wrap: break-word;
}

.post {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 6;
    /* number of lines to show */
    -webkit-box-orient: vertical;
    word-wrap: break-word;
}

.headin {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    /* number of lines to show */
    -webkit-box-orient: vertical;
    word-wrap: break-word;
    font-size: 16px;
    cursor: pointer;
    color: #007bff !important;
}

.headin a:hover {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    /* number of lines to show */
    -webkit-box-orient: vertical;
    word-wrap: break-word;
    font-size: 16px;
    cursor: pointer;
    text-decoration: underline;
    color: #007bff !important;
}

.article {
    font-size: 16px;
    font-family: "Proxima Nova";
}

.mission {
    font-size: 14px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    word-wrap: break-word;
    -webkit-line-clamp: 10;
    color: rgba(245, 245, 245, 0.872) !important;
    line-height: 1.35rem;
}

.workshops,
.projects {
    font-size: 16px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    word-wrap: break-word;
    // font-weight: 900;
    font-family: "Proxima Nova";
    margin: 10px 0px;
    line-height: 1.7rem;
}

.display {
    font-size: 22px;
}

@media (max-width: 1024px) {
    .responsive {
        max-width: 160px;
    }
}

@media (min-width: 900px) {
    .responsive {
        max-width: 160px;
        max-height: 240px;
    }
}

@media (min-width: 800px) {
    .responsive {
        max-width: 160px;
        max-height: 314px;
    }
}

@media (min-width: 600px) {
    .responsive {
        max-width: 160px;
        max-height: 380px;
    }
}

@media (min-width: 450px) {
    .responsive {
        max-width: 130px;
        max-height: 380px;
    }
}

@media only screen and (max-width: 1000px) {
    .gallery {
        // margin-left: 12px !important;
        margin-bottom: 100px;
    }

    .current-project {
        margin-top: 50px;
    }
}

@media only screen and (min-width: 1023px) and (max-width: 1024px) {
    .gallery {
        margin-left: 12px;
    }
}

.primary {
    color: rgba(245, 245, 245, 0.872);
    background-color: #007bff;
    border-color: #007bff;
}

.primary:hover {
    background-color: #007bff;
}

.secondary {
    border-radius: 11px;
    font-size: 24px;
}

.more {
    font-size: 19px !important;
}

.title {
    margin-bottom: 20px;
}

.slider {
    font-size: 18px !important;
}

.heading {
    font-size: 25px !important;
    margin-bottom: 20px !important;
}

.dark {
    background-color: rgb(34, 33, 33);
    overflow-x: hidden;
}

.event_content {
    height: 350px;
}

$assets: "~@/assets/";

@font-face {
    font-family: "Proxima Nova";
    src: url($assets + "font/ProximaNova-Regular.otf") format("opentype");
    font-weight: normal;
    font-style: normal;
}

@import "~@/assets/timeline/style.css";

.cd-timeline {
    background-color: transparent !important;

    .cd-timeline__content {
        border: 1px solid #ddd !important;
        box-shadow: none;
    }

    .container {
        max-width: 100%;
        padding-right: 0px;
        padding-left: 0px;
    }

    @media (min-width: 1200px) {
        .container {
            max-width: 100%;
        }
    }

    .text-component p {
        color: rgb(16, 16, 16) !important;
        font-size: 17px !important;
        font-family: "Proxima Nova";
    }

    .cd-timeline__date {
        font-family: "Proxima Nova";
    }
}
</style>