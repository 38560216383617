<template>
  <div class="summit">
    <!-- previous page button -->
    <section class="section-back-button">
      <a href="/">
          <i class="fas fa-long-arrow-alt-left"></i>
      </a>
    </section>
    <section class="mx-lg-4  py- site-container" id="bout">
        <div class="col-lg-12 col-md-12 col-sm-12">
          <div class="position">
            <h2 class="ml-3 header font-weight-bold">
              Exclusive Invite to RWBuild Code Summit
            </h2>
            <div class="col-12 row mx-md-5 mx-0">
              <div class="col-md-2 col-0"></div>
              <div class="col-md-7 col-sm-12 d-flex justify-content-center">
                <img
                  src="@/assets/images/summit/Speraker-College 1.jpg"
                  alt=""
                  class="expand img-fluid"
                  style="height: 100%; object-fit: contain"
                />
              </div>
              <div class="col-md-2 col-0"></div>
            </div>
          </div>
          <div class="my-4 mx-md-0 mx-sm-1 px-4">
            <p class="build">
              We are excited to announce the RWBuild Code Summit conference
              convening from <strong>Jan 18</strong> to
              <strong> Jan 21</strong> in Kigali. We have been focused on
              inviting the most significant software developers and companies to
              share and grow their software skills and knowledge. Due to COVID
              restrictions, we have a limited number of spots available. The
              January Summit will focus on software development for backend and
              frontend developers.
            </p>
          </div>

          <div class="my-4 mx-md-0 mx-sm-1 px-4">
            <p class="build">
              We are excited about the startup companies that are joining the
              Summit. In addition to them, we are thrilled with the great
              partners we have committed:
            </p>
            <ul class="build ml-4">
              <li>GIZ as a Summit Partner and Sponsor</li>
              <li>
                Keynote Speaker Dan Merfeld - a Two-Decade US Senior Developer
                brought to Rwanda (just for these events)
              </li>
              <li>
                Speakers on International Master of Computational Statistics and
                Machine Learning for Data Science
              </li>
              <li>Kigali International and Local Resident Developers</li>
              <li>Andela (pending)</li>
              <li>Speakers from ALU</li>
              <li>Muharo on Proper QA Testing</li>
              <li>
                Government Authorities to speak on Data Regulations & Resources
                (APIs) (Pending)
              </li>
              <li>Industry Partners to speak on Software Development</li>
              <li>
                International Business Coach and ‘Investor Pitch Master’ (for
                the developer-entrepreneur)
              </li>
              <li>Many others are ‘pending’ on commitment and availability.</li>
            </ul>
            <p class="build">
              This Summit is a free event for all developers invited who commit
              early. In addition, our capacity-building workshop sessions to
              follow will also be free, featuring senior developers with two
              decades of experience. (need to apply separately for summit &
              workshops).
            </p>
            <p class="build">
              We will be hosting tech leaders and promising entrepreneurs for
              networking and in-depth conversations. In addition, we have
              invited back senior international developers from our past
              successful code workshops (and are currently securing more senior
              developers to speak and participate).
            </p>
            <p class="build">
              We hope this code summit is not just the sharing of software
              development information but also addresses tech leaders’ role in
              advancing the software ecosystem of Kigali; for those sessions, we
              are inviting key stakeholders from the Rwandan government and
              organizations in Kigali.
            </p>
            <p class="build">
              If you are a software developer (and part of a startup) that would
              like to attend, please email us at
              opportunities@commonworldinc.com subject: (Apply for Code Summit)
              and/or (Apply for Code Workshops).
            </p>
          </div>
          <!-- key notes section  -->
          <div class="my-4 mx-md-0 mx-sm-1 px-md-4 px-1">
            <div class="heading font-bold">
              Key Note Speaker (visiting Rwanda for this event)
            </div>
            <div class="container-fluid row key-notes">
                <div class="col-lg-3 col-md-12 col-sm-12 mt-4">
                  <h5 class="font-weight-bold">Daniel P. Merfeld</h5>
                  <img
                    src="@/assets/images/summit/DanHeadShot.png"
                    alt=""
                    class="img-thumbnail expandable"
                  />
                </div>
                <div class="col-lg-9 col-sm-12 mt-5">
                  <p class="build">
                    Merfeld is the CEO and founder of TheoryThree Interactive,
                    CTO of JUMP! Coffee, President of Design Madison. Throughout
                    his twenty-plus-year career, his work has leveraged emerging
                    technologies to solve complex needs. With a mix of both
                    creative talent and programming ability, Merfeld’s work has
                    appeared in international ad campaigns, museum exhibits, and
                    products and services used by people all over the world.
                    Merfeld’s clients include; American Family Insurance,
                    American Express, Bonobos, <br />
                    Case Construction, Coach, Coach Outlet, Evco Plastics, Echo
                    Carving, Gucci,
                    Kate Spade, Kohl’s Corporation, Raven Software, The State of
                    Wisconsin, Stuart Weitzman, Tapestry, among others. In his
                    spare time, Merfeld leads multiple workshops aiding in the
                    capacity building of junior developers, including developers
                    from, Rwanda, Spain, India, and the United States.
                    In-Business Magazine recognized Dan as one of Madison’s “40
                    under 40” top professionals in 2006.
                  </p>
                </div>
            </div>
          </div>

          <div class="my-4 mx-md-0 mx-sm-1 px-md-4 px-1 my-5">
            <div class="d-flex gallery-wrapper p-3">
              <div class="col-md-6 col-sm-12">
                <img
                  src="@/assets/images/summit/RWBuild Summit Advertising Flag.png"
                  alt=""
                  class="img-fluid code-summit-graphic"
                />
              </div>
              <div class="col-md-6 col-sm-12 mt-lg-0 mt-3">
                <img
                  src="@/assets/images/summit/RWBuild Summit Advertising Graphic.png"
                  alt=""
                  class="img-fluid code-summit-graphic"
                />
              </div>
            </div>
            <div class="widget">
              <h4 class="headingf font-bold mb-3 ml-3">Gallery</h4>
            </div>
            <div class="d-flex  gallery-wrapper p-lg-3 p-md-1">
              <div class="col-md-6 col-sm-12 mt-2 mt-md-0">
                <img
                  src="@/assets/images/summit/RWBuild-Workshop-Collage-Promo-2.png"
                  alt=""
                  class="img-fluid code-summit-graphic"
                />
              </div>
              <div class="col-md-6 col-sm-12 mt-2 mt-md-0">
                <img
                  src="@/assets/images/summit/RWBuild-Workshop-Collage-Promo.png"
                  alt=""
                  class="img-fluid code-summit-graphic"
                />
              </div>
            </div>

            <div class="d-flex  gallery-wrapper p-md-3 p-sm-1">
              <div class="col-md-6 col-sm-12 mt-2 mt-md-0">
                <img
                  src="@/assets/images/summit/Speraker-College 2.jpg"
                  alt=""
                  class="img-fluid code-summit-graphic"
                />
              </div>
              <div class="col-md-6 col-sm-12 mt-2 mt-md-0">
                <img
                  src="@/assets/images/summit/Speraker-College 1 (1).jpg"
                  alt=""
                  class="img-fluid code-summit-graphic"
                />
              </div>
            </div>
          </div>
        </div>
    </section>
    <footer_comp/>
  </div>
</template>
<script>
import footer_comp from "@/components/footer.vue";
export default {
  name: "code-summit",
  components: {
    footer_comp,
  },
  mounted() {
    // apply lazy loading 
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          this.isVisiblePastEvents = true;
          observer.disconnect();
        }
      },
      {
        root: null,
        threshold: 0.1,
      }
    );
    observer.observe(this.$el);
  
}
};
</script>
<style scoped lang="scss">
ul li::before {
  content: "\2022";
  color: #118fd5;
  font-weight: bold;
  display: inline-block;
  width: 1em;
  font-size: 24px;
  // margin-left: -1em;
}
.summit p{
  line-height:1.7rem;
}
.footer {
  height: 30px !important;
  line-height: 1.5rem !important;
}
.section-back-button {
  background:#fff;
  position: relative;
  padding-left: 5%;
  padding-right: 5%;
  padding-top: 20px;
  .fa {
    font-size: 40px;
    color: #118fd5;
    cursor: pointer;
  }
}
.content p {
  color: gray;
  font-size: 18px;
  text-align: center;
}

img.expand {
  width: 72em;
}

.header {
  margin-top: 20px;
  margin-bottom: 20px;
  font-size: 35px !important;
}

.build {
  font-size: 16px !important;
  line-height:1.8rem;
}

.darky {
  background-color: rgb(34, 33, 33);
}

.position {
  text-align: center;
}

.heading {
  border-bottom: 5px solid #118fd5;
  width: 430px;
  font-size: 20px !important;
}

img.expandable {
  width: 300px;
  height: 328px;
  object-fit: cover;
  border: 1px solid #eef0f2;
}
.event-image-widget {
  height: 450px;
  width: 100%;
}
.event-image {
  height: 250px;
  width: 100%;
}
.event-image-widget img {
  height: 100% !important;
  object-fit: contain;
}
@media screen and (max-width: 1024px) {
  .event-image {
    height: auto;
    width: 100%;
  }
}
@media screen and (max-width: 1000px) {
  .key-notes{
    display:flex;
    flex-direction:column;

  }
  img.expand {
    width: 32em !important;
  }
  .row {
    display: flex;
    flex-direction: column;
  }

  .d-flex {
    display: flex;
    flex-direction: column;
  }
  .event-image-widget {
    height: auto;
    width: 100%;
  }
}
@media screen and (min-width: 768px) and (max-width: 1000px) {
  .gallery-wrapper {
    display: flex;
    flex-direction: row !important;
  }
}
@media screen and (max-width: 414px) {
  img.expand {
    width: 19em !important;
  }
  .d-flex {
    display: flex;
    flex-direction: column;
  }

  .heading {
    border-bottom: 5px solid #118fd5;
    width: auto !important;
    font-size: 20px !important;
  }
}
</style>
